import { lazy, memo, Suspense, useRef } from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Envelope } from 'assets/icons/icon-envelope.svg';
import Button from 'components/Button';
import FormField from 'components/FormComponents';
import Text from 'components/Text';
import { BASE_URL } from 'utils/constants';
import { validateForgotPassword } from 'validation';

import styles from './StepOne.module.scss';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const StepOne = memo(({ nextHandler, userInfo }) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'forgot_password_page.first_step',
  });
  const recaptchaRef = useRef();

  const onSubmitWithReCAPTCHA = async (value, actions) => {
    await recaptchaRef.current.executeAsync();

    nextHandler({ ...userInfo, ...value }, actions);

    recaptchaRef.current.reset();
  };

  return (
    <>
      <Text className={styles.title} color='light' tag='h1'>
        {t('enter_email')}
      </Text>
      <Formik
        initialValues={{
          email: userInfo.email,
        }}
        validationSchema={() => validateForgotPassword(i18n.t)}
        onSubmit={onSubmitWithReCAPTCHA}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormField
              type={'text'}
              component={'text'}
              name={'email'}
              id={'email'}
              label={t('email')}
              value={values.email}
              onChange={(event) => {
                const value = event.target.value;

                if (value.length > 50) return;

                setFieldValue('email', value);
              }}
              icon={<Envelope />}
              className={styles.field}
              errorType={'simple'}
            />

            <Button type={'submit'} className={styles.confirm}>
              {t('confirm')}
            </Button>
            <Button
              to={`${BASE_URL}`}
              className={styles.creataccount}
              appearance='simple'
            >
              {t('or_sign_in')}
            </Button>
          </Form>
        )}
      </Formik>
      <Suspense fallback={''}>
        <ReCAPTCHA
          theme='dark'
          ref={recaptchaRef}
          size='invisible'
          sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY}
        />
      </Suspense>
    </>
  );
});

StepOne.displayName = 'StepOne';

export default StepOne;
