import { AxiosRequestConfig } from 'axios';

import BaseRESTClient from '../../../BaseRESTClient';
import { TResponseError, TResponseSuccess } from '../../types';

import { TGlaucomaExam } from 'types/glaucoma/TGlaucomaExam';

/**
 * @function getGlaucomaData
 * @alias getGlaucomaData
 * @see https://github.com/axios/axios
 */

export interface TParams {
  examId: string;
}
export interface TPayload {
  iop: number;
}

export type TError = TResponseError;
export type TSuccess = TResponseSuccess<TGlaucomaExam>;

export async function saveIOP(
  this: BaseRESTClient,
  params: TParams,
  payload: TPayload,
  config?: AxiosRequestConfig
): Promise<TSuccess> {
  try {
    const { data } = await this.client.post<TSuccess>(
      `/examination/glaucoma/${params.examId}/save-iop`,
      payload,
      config
    );
    return data;
  } catch (error: any | TError) {
    if (error.data.status === 'Error') throw error.data;
    else
      throw {
        message: error?.message,
        status: error?.response.status,
      };
  }
}
