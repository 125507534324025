import { Language } from '../i18n';

const translation: Language = {
  dashboard: {
    error: {
      try_again: 'Tentar novamente',
    },
    banner: {
      title1: 'Por favor, entre em contato com',
      link: 'a equipe de vendas da Altris',
      title2: 'para estender sua assinatura',
    },
    empty_data: {
      title: 'Não há nenhum paciente adicionado',
      subtitle: 'Você pode adicionar um novo paciente clicando em',
      add_new_patient: 'adicionar novo paciente',
    },
    header: {
      close_filter: 'Fechar filtro',
      open_filter: 'Filtrar',
      search_placeholder: 'Buscar paciente',
      examination_filter: 'Filtro de exames',
      filter_form: {
        show_scan_by_date: 'Mostrar exame por data',
        all: 'Todos',
        today: 'Hoje',
        range: 'Intervalo',
        from: 'De',
        to: 'Para',
        created_by: 'Filtro de paciente',
        doctor_select: 'Selecionar médico',
        apply: 'Aplicar',
        clear: 'Limpar filtro',
      },
      add_new_patient: 'Adicionar novo paciente',
      add_new_examination: 'Adicionar novo exame',
      back: 'Voltar',
    },
    patient_list: {
      header: {
        patient_id: 'ID do paciente',
        patient_name: 'Nome do paciente',
        birth_date: 'Data de nascimento',
        gender: 'Sexo',
        last_upload: 'Último upload',
        severity_level: 'Nível de severidade',
        description: 'Descrição',
      },
      item: {
        patient_id: 'ID do paciente',
        patient_name: 'Nome do paciente',
        birth_date: 'Data de nascimento',
        gender: 'Sexo',
        last_upload: 'Último upload',
        severity_level: 'Nível de severidade',
        description: 'Descrição',
        edit: 'Editar',
        delete: 'Excluir',
        back: 'Voltar',
        show_examinations: 'Mostrar exames',
      },
    },
  },
  options: {
    buttons: {
      add: 'adicionar',
      examination: 'exame',
    },
  },
  contact_us_page: {
    contact_us: 'Fale conosco',
    fill_the_form: 'Preencha o formulário abaixo',
    example_short: 'Ex',
    first_name: 'Primeiro nome',
    email: 'E-mail',
    message: 'Sua mensagem',
    submit_form: 'Enviar formulário',
    attach_file: 'Anexar arquivo',
    attach_screenshot:
      'Você pode anexar uma captura de tela à sua mensagem para mostrar qualquer dificuldade que tenha encontrado. Isso pode ajudar nossa equipe de suporte a encontrar a opção certa para você mais rapidamente.',
  },
  demo_user_registration: {
    back: 'Voltar',
    banner_text_1: 'Qualquer OCT',
    banner_text_2:
      'Explore como a IA analisa o OCT:\nas digitalizações estão dentro',
    banner_text_3: 'Digitalização OCT com Altris AI',
    first_step: {
      welcome: 'Bem-vindo ao Altris AI',
      email_label: 'Digite seu e-mail para validar:',
      email: 'E-mail',
      info: 'Continuar implica em seu consentimento automático para que o Altris AI armazene seu e-mail com o propósito de enviar materiais de marketing.',
      submit_form: 'Enviar confirmação',
    },
    second_step: {
      enter_security_code: 'Digite o código de segurança',
      send_to: 'Este código de segurança foi enviado para:',
      submit: 'Confirmar',
      resend_code: 'Enviar código novamente',
      seconds_short: 'seg',
    },
    third_step: {
      add_some_info: 'Adicionar algumas informações',
      full_name_label: 'Digite seu nome completo',
      full_name_placeholder: 'Nome completo',
      country_label: 'País',
      country_placeholder: 'Escolha o país',
      oct_label: 'Sistema OCT',
      oct_placeholder: 'Selecione seu sistema OCT',
      profession_label: 'Profissão',
      profession_placeholder: 'Escolha uma profissão',
      decision_maker_label:
        'Você é um tomador de decisão em sua clínica/instituição?',
      promo_code_label: 'Código promocional (opcional)',
      promo_code_info:
        'Entre em contato com o distribuidor Altris ou com a equipe de vendas Altris',
      promo_code_placeholder: 'Digite o código promocional',
      submit: 'Concluir',
    },
  },
  education: {
    title: 'Junte-se à Altris Education',
    subtitle1:
      'O Altris Education OCT se tornará o seu guia no aprendizado de interpretação de OCT. Este aplicativo móvel é interativo, gratuito e está disponível a qualquer hora e em qualquer lugar. Os métodos educacionais tradicionais, como webinars, conferências e atlas, não são suficientes para os alunos modernos: é hora de usar aplicativos móveis gratuitos.',
    education: 'Educação',
    subtitle2:
      'No Altris Education OCT, você terá acesso a milhões de exames OCT rotulados e fará parte de uma comunidade de especialistas em cuidados com os olhos ao mesmo tempo. Você terá a chance única de enviar exames OCT controversos e discuti-los com colegas de todo o mundo. Com o Altris Education OCT, estabelecemos padrões diagnósticos mais elevados, o que resulta em melhores resultados para os pacientes.',
    learn_more: 'Saiba mais',
    download: 'Baixar o aplicativo Altris Education',
    oct_scans: 'Exames OCT',
    experts: 'Especialistas',
    retina_experts: 'Especialistas em Retina rotulam cada exame',
    eye_care_specialists:
      'Especialistas em cuidados com os olhos na comunidade',
    pathological_signs: 'sinais patológicos',
    pathologies: 'patologias',
  },
  forgot_password_page: {
    first_step: {
      enter_email: 'Digite seu e-mail',
      email: 'E-mail',
      confirm: 'Confirmar',
      or_sign_in: 'ou Entrar',
    },
    second_step: {
      congratulations: 'Parabéns!',
      check_email: 'Por favor, verifique seu e-mail',
      back_to_sign_in: 'Voltar ao login',
    },
  },
  maintenance_mode: {
    title: 'Site em manutenção!',
    subtitle:
      'Este site está passando por manutenção programada. Voltaremos em breve.',
    refresh_page: 'Atualizar página',
  },
  mobile_availability: {
    title: 'Bem-vindo ao Altris AI',
    subtitle:
      'O Altris AI só pode ser usado em PC. Por favor, abra a plataforma no seu computador pessoal para obter uma experiência completa.',
    link: 'Link',
    copy_link: 'Copiar link',
    mobile_subscription: {
      title: 'Assinaturas',
      subtitle:
        'Escolha seu plano de assinatura para começar a usar o Altris AI.',
      services: 'Serviços',
    },
  },
  offline: {
    title: 'Sua conexão está perdida!',
    subtitle: 'Por favor, verifique sua conexão com a Internet...',
  },
  examination: {
    unavailable: {
      title: 'Dados insuficientes',
      subtitle:
        'Não há dados suficientes no DICOM para revisar o exame. Por favor, preencha o formulário vazio no topo da página.',
    },
    header: {
      patient_id: 'ID do paciente',
      patient_name: 'Nome do paciente',
      patient_name_placeholder: 'Primeiro nome',
      patient_surname: 'Sobrenome do paciente',
      patient_surname_placeholder: 'Sobrenome',
      birth_date: 'Data de nascimento',
      gender: 'Sexo',
      gender_placeholder: 'Selecione',
      examination_date: 'Data do exame',
      notes: 'Anotações',
      report_unavailable: 'Infelizmente, os relatórios não estão disponíveis.',
      select_plan: 'Selecione um plano',
      to_use_this_feature: 'para usar este recurso.',
      report: 'Relatório',
      export_unavailable:
        'Infelizmente, a exportação não está disponível para esta varredura.',
      export: 'Exportar',
      save: 'Salvar',
      patient_info: 'Informações do paciente',
      male: 'Masculino',
      female: 'Feminino',
      other: 'Outro',
      other_patient_examinations: 'Exames de outros pacientes',
      examination_label:
        '{{name}} / exame: {{data}} / Olho: {{eye}} / varredura: {{scan}}',
    },
    tabs: {
      single_scan: 'EXAME ÚNICO',
      pathologies_detection: 'DETECÇÃO DE PATOLOGIAS',
      measurements: 'MEDIÇÕES',
      all_scans: 'VISÃO DE TODOS OS EXAMES',
      both_eyes: 'VISÃO DE AMBOS OS OLHOS',
      comparison: 'COMPARAÇÃO DE ESPESURA',
      progression: 'PROGRESSÃO DE PATOLOGIA',
      glaucoma: 'ANÁLISE DE RISCO DE GLAUCOMA',
    },
    single_scan: {
      severity: 'Gravidade',
      off: 'Desligado',
      thickness_map_off: 'Mapa de espessura desativado',
      on: 'Ligado',
      thickness_map_on: 'Mapa de espessura ativado',
      average_thickness: 'Espessura média de {{metrics}} mm, μm',
      layers_failed: {
        title: 'Algo deu errado durante a análise das camadas.',
        subtitle: 'Por favor, escreva sobre este problema para o suporte.',
      },
      retina_thickness_map: 'Mapa de espessura da retina',
    },
    pathologies_detection: {
      classification: 'Classificação',
      no_access:
        'Você não tem acesso às segmentações. Por favor, use o formulário de contato dentro do seu perfil.',
    },
    measurements: {
      not_available: 'As medições não estão disponíveis para o exame atual.',
      failed:
        'Falha nas medições. Por favor, escreva sobre este problema para o suporte.',
      in_progress:
        'Medições em andamento. Por favor, verifique esta página um pouco mais tarde.',
      no_plan:
        'Seu plano não inclui análise de medições. Por favor, atualize seu plano.',
      scan: {
        measurements: 'Medições',
        auto: 'Automático',
        manual: 'Manual',
        segmentation: 'Segmentação',
        length: 'Comprimento',
        add_measurements: 'Adicionar medições',
        no_lines: 'Nenhuma linha para a varredura atual',
        highlight: 'Destacar',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        reset_measurements: 'Reiniciar medições',
        no_pathologies: 'Nenhuma patologia para a varredura atual',
      },
      table: {
        segmentation_area: 'Área de segmentação',
        segmentation_volume: 'Volume de segmentação',
        combined: 'Combinado',
        separate: 'Separado',
        area_headings: {
          all_segmentations: 'Todas as segmentações',
          b_scan_area: 'Área da varredura B',
          b_scan_area_view: 'Visualização da área da varredura B',
          en_face_area_view:
            '<span>Visualização facial (mm<sup>2</sup> * 1000)</span>',
        },
        volume_headings: {
          all_segmentations: 'Todas as segmentações',
          measured_volume_wrap: 'Volume medido<br />(ETDRS {{number}}mm)',
          measured_volume: 'Volume medido (ETDRS {{number}}mm)',
          en_face_area_view:
            '<span>Visualização facial <br> (mm<sup>3</sup>  * 1000)</span>',
          en_face_area_view_nl: 'Visualização facial',
        },
        no_pathology: 'Nenhuma patologia para a varredura atual',
      },
    },
    all_scans: {
      no_scans: {
        title: 'Nenhuma varredura com esses parâmetros.',
        subtitle: 'Por favor, tente outros filtros.',
      },
      severity: 'Gravidade',
    },
    both_eyes: {
      add_fellow_eye: 'Adicionar olho contralateral',
      no_plan:
        'Seu plano não inclui análise de Ambos os Olhos. Por favor, atualize seu plano.',
      severity: 'Gravidade',
      left_eye: 'Horizontal, Esquerdo (OE)',
      right_eye: 'Horizontal, Direito (OD)',
      average_thickness: '{{metrics}} mm espessura média, μm',
      sync: 'Sincronizar.',
      different_scan_number:
        'Dois exames possuem um número diferente de varreduras, portanto a rolagem não pode ser sincronizada.',
    },
    comparison: {
      add_examination: 'Adicionar exame para comparação',
      no_plan:
        'Seu plano não inclui análise de Comparação. Por favor, atualize seu plano.',
      retina_thickness_difference: 'Diferença de espessura da retina',
      retina_thickness_difference_tooltip:
        'Mostra a diferença de espessura da retina entre os exames, do exame mais recente para o mais antigo.',
      average_thickness: '{{metrics}} mm espessura média, μm',
      sync: 'Sincronizar.',
      different_scan_number:
        'Dois exames possuem um número diferente de varreduras, portanto a rolagem não pode ser sincronizada.',
      severity: 'Gravidade',
    },
    progression: {
      no_plan:
        'Seu plano não inclui análise de Progressão. Por favor, atualize seu plano.',
      severity: 'Gravidade',
      exam_does_not_have_date: 'Um dos exames não possui data.',
      oldest_to_newest: 'Mais antigo - Mais recente',
      newest_to_oldest: 'Mais recente - Mais antigo',
      sort_by_date: 'Ordenar por data',
    },
    glaucoma: {
      add_fellow_eye: {
        button: '+ Adicionar olho companheiro',
        tooltip:
          'Não há olho companheiro no exame do paciente. Por favor, adicione antes de continuar',
      },
      add_examination: 'Adicionar outro exame',
      unavailable: {
        heading:
          'A análise de risco de glaucoma está<span> indisponível para este exame.</span> Isso pode ser devido a:',
        pathologies_present:
          'Patologias presentes nas imagens que afetam o complexo GCC, impossibilitando um cálculo preciso.',
        other_factors: 'Outros fatores que impedem uma análise precisa.',
        add_exam: 'Adicionar exame',
      },

      alert: {
        red: {
          title: 'Alto nível de risco de glaucoma',
          description:
            'A diferença perceptível na espessura do GCC entre os hemisférios superior (S) e inferior (I) indica sinais potenciais de glaucoma.',
        },
        yellow: {
          title: 'Nível médio de risco de glaucoma',
          description:
            'Embora as variações detectadas na espessura do GCC dos hemisférios superior (S) e inferior (I) não sejam significativas o suficiente para causar preocupação, um exame e monitoramento adicionais são recomendados para uma compreensão abrangente do risco.',
        },
        green: {
          title: 'Sem risco de glaucoma',
          description:
            'A diferença de espessura calculada entre a espessura dos hemisférios superior (S) e inferior (I) do GCC está dentro dos parâmetros clinicamente aceitáveis.',
        },
      },
      target: {
        gcc_analysis: 'Análise GCC',
        gcc_thickness: 'Espessura GCC',
        superior_inferior: 'Superior / Inferior',
      },
      info: {
        gcc_asymmetry: 'Assimetria GCC',
        od: 'OD (Direito)',
        os: 'OS (Esquerdo)',
        tooltip: {
          title1: 'A Análise de Assimetria GCC',
          title2: ' avalia dois indicadores:',
          first_item1:
            'Índice de Assimetria: Este parâmetro mede o desequilíbrio entre os setores superior e inferior e muda de cor de acordo com a Proporção S/I:',
          first_item2: 'Verde',
          first_item3: ' - Sem assimetria',
          first_item4: 'Amarelo',
          first_item5: ' - Assimetria moderada',
          first_item6: 'Vermelho',
          first_item7: ' - Assimetria',
          second_item1: 'A ',
          second_item2: 'espessura dos setores Superior e Inferior',
          second_item3: ' separadamente.',
          description:
            'Com base em um desvio desses indicadores das faixas esperadas, calculamos e exibimos o alerta de risco de glaucoma.',
        },
        asymmetry_index: 'Índice de assimetria',
        superior_thickness: 'Espessura superior',
        inferior_thickness: 'Espessura inferior',
        normal: 'Normal',
        glaucoma_alert: 'Alerta de glaucoma',
        iop: 'Druck intraocular (IOP)',
        superior: 'Superior',
        inferior: 'Inferior',
      },
      hemisphere: {
        title: 'Assimetria de espessura GCC do hemisfério S-I',
        heading: 'Assimetria hemisférica',
        superior: 'Superior',
        inferior: 'Inferior',
        description: {
          description_items: {
            posterior_pole_asymmetry_analysis:
              '<strong>Análise da Assimetria do Polo Posterior</strong> combina a mapeamento da espessura da retina no polo posterior com a análise da assimetria entre os olhos e entre os hemisférios de cada olho.',
            posterior_pole_retina_thickness_map:
              '<strong>Mapa da Espessura da Retina do Polo Posterior</strong> mostra a espessura da retina em todo o polo posterior (varredura OCT de volume de 30° x 25°) para cada olho. Escala de cores comprimida é utilizada para localizar até as menores diferenças na espessura da retina. Mapa de Assimetria OS-ODcompara o olho esquerdo com o olho direito.',
            analysis_grid_8x8:
              '<strong>Grade de Análise 8x8</strong> A grade 8x8 está posicionada de forma simétrica em relação ao eixo fovea-disco. Para cada célula da grade, a espessura média da retina é indicada.',
            hemisphere_analysis_displays:
              '<strong>Exibições da Análise dos Hemisférios</strong> mostram a assimetria entre o hemisfério superior e o inferior. O eixo fovea-disco é a linha de simetria horizontal. Para cada célula de um hemisfério, a espessura média da retina é comparada com o valor na célula correspondente do hemisfério oposto.',
          },
          title: '<strong>Descrição</strong>',
          item1: 'Assimetria de espessura GCC do hemisfério S-I',
          item2:
            'Ilustra a assimetria da espessura do GCC (RNFL+GCL+IPL) entre o hemisfério superior e inferior.',
          item3: 'Uma grade 8x8',
          item4:
            'é aplicada em uma área macular de 6x6 mm para ilustrar a assimetria na espessura do GCC entre os quadrantes simétricos dos hemisférios superior e inferior.',
          item5:
            'A diferença na espessura do GCC é fornecida nos quadrados simétricos.',
          item6: 'NB!',
          item7:
            'Para uma avaliação qualitativa da espessura do GCC, é necessário levar em consideração a presença de alterações exudativas na zona macular da retina e avaliar os dados de espessura do GCC em um olho contralateral.',
        },
      },
      progression: {
        heading: 'Progressão do Risco de Glaucoma',
        add_exam: 'Adicionar outro exame para revisar a progressão do glaucoma',
        add_all_exam: 'Todos os exames',
        swiper: {
          total_change: 'Mudança Total',

          item: {
            edit: 'EDITAR',
            normal: 'Normal',
            glaucoma_alert: 'Alerta de glaucoma',
          },
          tooltip: {
            type_error: 'O valor deve ser um número',
            positive: 'O valor deve ser maior que 0',
            max: 'O valor é muito elevado',
          },
        },
        modal: {
          title: 'Selecione os exames que deseja adicionar',
          description:
            'Você tem mais de 8 exames permitidos que podem ser adicionados ao Progresso. Por favor, selecione quais deseja adicionar',
          select_exam: 'Selecionar todos os exames',
          test: 'Teste',
          scans: 'Scans',
          selected: 'Selecionado',
          select_button: 'Selecionar',
        },
        graph: {
          superior_inferior_graph: {
            title: 'Espessura Superior / Inferior',
            tooltip: {
              asymmetry_index: 'Índice de Assimetria',
              superior_thickness: 'Espessura Superior',
              inferior_thickness: 'Espessura Inferior',
            },
            legend: {
              superior: '- Superior',
              inferior: '- Inferior',
            },
          },
          ocular_graph: {
            title: 'Pressão Intraocular (PIO)',
          },
        },
      },
    },
  },
  onboarding_page: {
    title: 'Introdução',
    all_scans: 'Todas as varreduras',
    both_eyes: 'Ambos os olhos',
    comparison: 'Comparação',
    create_examination: 'Criar exame',
    create_patient: 'Criar paciente',
    header: 'Cabeçalho',
    measurements: 'Medições',
    pathologies_detection: 'Detecção de patologias',
    progression: 'Progressão',
    report: 'Relatório',
    single_scan: 'Visualização individual',
  },
  register_user_page: {
    step_one: {
      sign_up_with_google: 'Inscreva-se com o Google',
      or: 'ou',
      sing_up_with_email: 'Criar uma conta com e-mail',
      email_label: 'E-mail corporativo',
      website_label: 'Site da instituição',
      first_name_label: 'Primeiro nome',
      last_name_label: 'Sobrenome',
      next: 'Próximo',
      or_sign_in: 'ou Entrar',
    },
    step_two: {
      create_an_account: 'Criar uma conta',
      password_label: 'Criar uma senha',
      repeat_password_label: 'Repetir a senha',
      next: 'Próximo',
      back: 'Voltar',
    },
    step_three: {
      create_an_account: 'Criar uma conta',
      profession_placeholder: 'Escolha uma profissão',
      country_placeholder: 'Seu país',
      i_agree_with: 'Concordo com',
      privacy_policy: 'Política de Privacidade',
      and: 'e',
      terms_and_conditions: 'Termos e Condições',
      back: 'Voltar',
    },
    step_thankyou: {
      title: 'Parabéns!',
      subtitle: 'Sua conta foi criada com sucesso!',
      button: 'Vamos começar',
    },
  },
  reset_password_page: {
    enter_new_password: 'Digite sua nova senha',
    password_label: 'Criar uma senha',
    repeat_password_label: 'Repetir a senha',
    next: 'Próximo',
  },
  settings_page: {
    tabs: {
      profile: 'Perfil',
      report_settings: 'Configurações do relatório',
      progression_settings: 'Configurações de progressão',
      functions: 'Funções',
      change_password: 'Alterar senha',
      accounts_data: 'Dados da conta',
    },
    accounts_data: {
      title: 'Dados da conta',
      subtitle:
        'Crie uma cópia do conteúdo da sua conta Altris AI para fazer backup.',
      no_examinations: 'Você não possui exames criados para gerar um backup',
      request_data: 'Solicitar dados pessoais',
      in_progress: 'Seus dados estão sendo gerados. Aguarde um pouco.',
      cancel_backup: 'Cancelar a criação de backup do usuário',
      download_data: 'Baixar dados',
      download_info:
        'Aqui estão seus dados. Você pode baixá-los clicando no botão acima. Este arquivo zip será salvo em {{date}}. Após esta data, você poderá criar um backup novamente.',
      delete_data: 'Excluir dados pessoais',
      delete_info:
        'Você também pode excluir este arquivo zip e criar um backup novamente.',
      code_send: 'O código de confirmação foi enviado para o seu e-mail.',
      attempts_left: 'Tentativas restantes',
      example_short: 'Ex',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    change_password: {
      password_label: 'Sua senha anterior',
      password_placeholder: 'Digite sua senha',
      new_password_label: 'Sua nova senha',
      new_password_placeholder: 'Digite sua nova senha',
      repeat_new_password_label: 'Repetir senha',
      repeat_new_password_placeholder: 'Digite sua senha novamente',
      unable_to_change_password: 'Desculpe, você não pode alterar sua senha',
      submit: 'Atualizar minha senha',
      code_send: 'O código de confirmação foi enviado para o seu e-mail.',
      attempts_left: 'Tentativas restantes',
      example_short: 'Ex',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },
    functions_settings: {
      title: 'Configurações',
      subtitle: 'Altere as configurações a partir do formulário abaixo',
      save: 'Salvar configurações',
      on: 'ligado',
      off: 'desligado',
    },
    profile: {
      example_short: 'Ex',
      first_name: 'Primeiro nome',
      last_name: 'Sobrenome',
      email: 'E-mail',
      save: 'Salvar alterações',
      country: 'País',
      country_placeholder: 'Seu país',
      profession: 'Profissão / especialização',
      profession_placeholder: 'Escolha uma profissão',
      language: 'Idioma',
    },
    progression_report: {
      title:
        'Selecione as patologias que deseja remover do acompanhamento na progressão da patologia',
      subtitle:
        'Se você desativar a patologia, ela não será mostrada na progressão da patologia.',
      on: 'ligado',
      off: 'desligado',
      save_settings: 'Salvar configurações',
    },
    report_settings: {
      clinic_name: 'Nome da clínica',
      clinic_email: 'E-mail da clínica',
      save: 'Salvar alterações',
    },
  },
  sign_in_page: {
    email_label: 'E-mail corporativo',
    password_label: 'Senha',
    forgot_password: 'Esqueceu a senha?',
    sign_in: 'Entrar',
    or: 'ou',
    sign_in_with_google: 'Entrar com Google',
  },
  subscription_page: {
    b2c: {
      subscription: 'Assinatura',
      billing: 'Cobrança',
      subscription_expired:
        'A assinatura que você está usando expirou. Atualize para um novo',
      subscription_page: 'Página de assinatura',
      unsubscribe: 'Você pode cancelar a assinatura.',
      already_unsubscribed:
        'Você já cancelou o seu plano de assinatura antigo. Seu plano atual estará ativo até {{date}}',
      choose_plan:
        'Escolha o seu plano ideal. Sem contratos. Sem taxas surpresa.',
    },
    common: {
      retina_layers_detected: 'Condições da retina detectadas pela Altris AI',
    },
  },
  team_members_page: {
    title: 'Membros da equipe',
    invite_doctor: 'Convidar médico',
    max_doctors:
      'Há um número máximo de médicos que você pode convidar. Se você quiser convidar alguém novo, deve primeiro excluir um da sua conta.',
  },
  components: {
    aside: {
      back: 'Voltar',
    },
    context_modal: {
      smart_report: {
        create_examination_report: 'Criar relatório do exame',
        standard: 'Padrão',
        standard_plus: 'Padrão+',
        advanced: 'Avançado',
        custom: 'Personalizado',
        progression: 'Progressão',
        creating_report: 'Criando relatório...',
        loading_resources_for_report:
          'Carregando recursos para o relatório {{percentage}}',
        cancel: 'Cancelar',
        preview: 'Visualizar',
        components_loaded: 'Componentes carregados:',
        images_loaded: 'Imagens carregadas:',
        heatmaps_loaded: 'Mapas de calor carregados:',
        progression_report: {
          description1:
            'Visualizando a evolução das patologias ao longo do tempo usando a área de B-Scan, área En-face e volumes. Por favor, escolha as patologias específicas de interesse para incluir no relatório.',
          important: 'Nota importante:',
          description2:
            'Selecione apenas as patologias que são pontos de interesse para evitar que o relatório se torne excessivamente extenso.',
          select_pathologies: 'Selecionar patologias:',
          all: 'Todas',
          on: 'ATIVADO',
          off: 'DESATIVADO',
        },
        preset: {
          report_includes: 'O relatório inclui:',
          severity_detection: 'Detecção de gravidade',
          severity_detection_description:
            'Mostra quantas varreduras graves há no exame e o quão graves elas são.',
          oct_description: 'Descrição da análise OCT da Altris AI',
          oct_description_description:
            'Inclui a descrição textual da análise do exame.',
          retina_layers: 'Segmentação das camadas da retina',
          retina_layers_description:
            'Representa todas as camadas da retina, realçadas em cores diferentes para um uso conveniente.',
          both_eyes: 'Visualização de ambos os olhos',
          both_eyes_description:
            'Mostra os dois olhos opostos para comparar a condição clínica.',
          both_eyes_unavailable:
            'Você deve adicionar uma segunda varredura em "Visualização de ambos os olhos" para habilitar isso.',
          etdrs_calculations: 'Cálculos de ETDRS',
          etdrs_calculations_description:
            'Inclui média de 1, 3 e 6 mm; Espessura de várias camadas (se selecionado na plataforma); Espessura da camada selecionada (se selecionado na plataforma).',
          comparison: 'Comparação de espessura',
          comparison_description:
            'Inclui dois exames do mesmo olho e alterações de espessura no ETDRS.',
          comparison_unavailable:
            'Você deve adicionar uma segunda varredura em "Comparação" para habilitar isso.',
          auto_measurements: 'Medições automáticas',
          auto_measurements_description:
            'Inclui medições lineares de patologias, calculadas automaticamente.',
          manual_measurements: 'Medições manuais',
          manual_measurements_description:
            'Inclui medições lineares de patologias, calculadas manualmente (se selecionado na plataforma).',
          segmentation_area: 'Área de segmentação',
          segmentation_area_description:
            'Mostra a lista de todas as patologias no exame e suas áreas de B-Scan e En face.',
          segmentation_volume: 'Volume de segmentação',
          segmentation_volume_description:
            'Mostra a lista de todas as patologias no exame e seu volume (em mm^3 ou nL).',
          pathology_detection:
            'Detecção de patologia (3 varreduras mais patológicas)',
          pathology_detection_description:
            'Inclui as três varreduras mais patológicas, todas as segmentações e classificações para essas varreduras.',
          referral_urgency_score: 'Pontuação de urgência de encaminhamento',
          referral_urgency_score_description:
            'Mostra o quão urgentemente é necessário encaminhamento para este caso, dependendo das classificações reconhecidas.',
          glaucoma: 'Análise de risco de glaucoma',
          glaucoma_description:
            'A análise de risco de glaucoma só está disponível para exames onde sinais patológicos não afetam o GCC.',
        },
        custom_report: {
          analyze_other_scan: 'Analisar outra varredura',
          check_scan_that_you_want_to_analyze:
            'Marque a varredura que deseja analisar.',
          etdrs_calculations_description:
            'Permitido apenas para arquivos Dicom que incluem informações ETDRS. Aguarde até que a análise ETDRS seja concluída com sucesso para uma visualização correta no relatório.',
          auto_measurements_description:
            'Sua análise de medição deve ser finalizada para habilitar isso.',
          auto_measurements_unavailable:
            'Este serviço não está disponível em sua assinatura. Atualize para Pro para usar.',
          manual_measurements_description:
            'Sua análise de medição deve ser finalizada e você deve desenhar uma linha dentro das medições manuais para habilitar isso.',
          manual_measurements_unavailable:
            'Este serviço não está disponível em sua assinatura. Atualize para Pro para usar.',
          segmentation_area_description:
            'Sua análise de medição deve ser final',
          segmentation_area_unavailable:
            'Este serviço não está disponível em sua assinatura. Atualize para Pro para usar.',
          both_eyes: 'Ambos os olhos',
          segmentation_volume_description:
            'Sua análise de medição deve ser finalizada para habilitar isso.',
          segmentation_volume_unavailable:
            'Este serviço não está disponível em sua assinatura. Atualize para Pro para usar.',
          comparison: 'Comparação',
          selected_scan: 'Varredura selecionada:',
          select_other: 'Selecionar outra',
          added_scans: 'Varreduras adicionadas:',
          add_other: 'Adicionar outra',
          no_selected_layers: 'Nenhuma camada selecionada.',
          select_layers: 'Selecionar camadas',
        },
      },
    },
    countdown_timer: {
      days: 'Dias',
      hours: 'Horas',
      minutes: 'Mins',
      seconds: 'Seconds',
    },
    create_examination: {
      upload_archived_file:
        'Carregar pasta arquivada (.zip), exportada do seu OCT. Principalmente relevante para Heidelberg Engineering OCT.',
      create_patient_from_dicom: 'Criar um novo paciente de um DICOM',
      create_patient_from_dicom_description:
        'Informações sobre o paciente serão obtidas do arquivo DICOM',
      notes_label: 'Ex.: Minha anotação',
      notes_placeholder: 'Notas para o exame',
      file_label: 'Enviar DICOM para completar',
      multiple_file_label: 'Envie até dois arquivos DICOM do mesmo paciente',
      multiple_file_description:
        'Certifique-se de que pertencem ao mesmo paciente e incluem os olhos direito e esquerdo',
      scan_width_label: 'Largura da varredura',
      scan_width_placeholder: 'Largura',
      scan_width_message:
        'Certifique-se de ter selecionado a largura de varredura correta, pois ela afeta as medidas do exame',
      eye_label: 'Olho',
      eye_placeholder: 'Tipo',
      examination_date: 'Data do exame',
      upload_images_to_complete: 'Envie imagens para concluir',
      right: 'Direito',
      left: 'Esquerdo',
    },
    current_plan_info: {
      current_plan: 'Plano atual',
      examinations_available: 'Exames disponíveis',
      examinations_per_month: '{{count}} / Mês',
      start_date: 'Data de início',
      expiration_date: 'Data de validade',
      period: 'Período',
      amount: 'Valor',
      status: 'Status',
    },
    detailed_scan: {
      scan_detail: 'Detalhe da varredura',
    },
    drag_drop_list: {
      different_scan_count:
        'Dois exames possuem número diferente de varreduras, portanto a rolagem não pode ser sincronizada',
      add: 'Adicionar',
      examination: 'exame',
      no_scans_with_such_severity: 'Nenhuma varredura com essa gravidade',
      average_thickness: '{{metrics}} mm de espessura média, μm',
    },
    error_fallback: {
      something_went_wrong: 'Algo deu errado',
      fix_problem: 'Corrigir problema',
    },
    etdrs_target: {
      single_layer_thickness: 'Espessura de camada única',
      multiple_layers_thickness: 'Espessura de camadas múltiplas',
      gcc_thickness: 'Espessura GCC',
    },
    examination_list: {
      empty: {
        title: 'Sua lista de projetos está vazia',
        subtitle: 'Você pode adicionar um novo exame pressionando',
        add_new_examination: 'Adicionar novo exame',
      },
      header: {
        examinations: 'Exames',
        uploaded: 'Enviado',
        date: 'Data',
      },
      item: {
        eye: 'Olho',
        examination_failed: 'Exame falhou',
        right_short: 'R',
        left_short: 'L',
        image: 'Imagem',
        edit: 'Editar',
        delete: 'Excluir',
      },
    },
    exam_stat_info: {
      no_severity: 'Sem gravidade (Verde):',
      moderate_severity: 'Gravidade moderada (Amarelo):',
      severe: 'Grave (Vermelho):',
      scans: 'Varreduras',
      severity: 'Gravidade',
    },
    feedback: {
      title: 'Feedbacks dos clientes',
    },
    first_subscription: {
      subscription: 'Assinatura',
      chose_plan:
        'Escolha o seu plano perfeito. Sem contratos. Sem taxas surpresa.',
    },
    form_components: {
      file_upload: {
        upload_your_date_here: 'Carregue seus dados aqui',
        add_more_examinations: 'Adicionar mais exames',
        remove_file: 'Remover arquivo{{ending}}',
        upload_file: 'carregar arquivo',
        you_added_files_one: 'Você adicionou {{count}} arquivo',
        you_added_files_other: 'Você adicionou {{count}} arquivos',
      },
    },
    form_wrap: {
      help: 'Ajuda',
    },
    header: {
      view_my_account: 'Ver a minha conta',
      sign_out: 'Sair',
      subscriptions: 'Assinaturas',
      examinations_left: 'Exames restantes',
      unlimited: 'Ilimitado',
      add_new_patient: 'Adicionar novo paciente',
      add_new_examination: 'Adicionar novo exame',
      menuItems: {
        patients: 'Pacientes',
        settings: 'Configurações',
        subscription: 'Assinatura',
        team_members: 'Membros da equipe',
        contact_us: 'Contate-nos',
        onboarding: 'Integração',
      },
    },
    layers_filter: {
      hide: 'Ocultar',
      show_more: 'Mostrar mais',
      no_items: 'IA não definiu nenhuma área para ser segmentada.',
      all: 'Todos',
      layers: 'Camadas:',
      segmentations: 'Segmentações:',
      highlight: 'Destacar',
    },
    layout: {
      private_layout: {
        skip: 'Pular',
        demo_banner_title:
          'Inicie um teste gratuito de 14 dias após a apresentação em vídeo',
        book_a_demo: 'Agende uma ligação',
      },
      simple_layout: {
        sign_out: 'Sair',
      },
    },
    modal: {
      analyze_scans: {
        severity: 'Gravidade',
        analyze_all_scans: 'Analisar todas as varreduras',
        no_scans: 'Nenhuma digitalização com esses parâmetros.',
        try_another_filter: 'Por favor, tente outro filtro.',
        submit: 'Enviar',
      },
      booking_meeting: {
        title:
          'Temos certeza de que podemos encontrar a solução perfeita para você!',
        book_a_demo: 'Agendar demonstração',
      },
      buy_additional_items: {
        buy_additional_examinations: 'Comprar exames adicionais {{number}}',
        will_be_cost: '{{description}} custará {{symbol}}{{price}}',
      },
      create_examination: {
        title1:
          'Preencha o formulário abaixo para adicionar um novo exame. Envie ',
        title2: 'SOMENTE B-Scans horizontais da Zona Macular',
        title3: ' (Imagem de fundo, varreduras do disco óptico ',
        title4: 'NÃO SÃO',
        title5: ' suportados)',
        select_exam_label: 'Selecionar o exame',
        patient_id_label: 'ID do paciente',
        patient_id_placeholder:
          'O paciente será criado a partir do arquivo DICOM',
        remaining_examinations: 'Exames restantes: {{number}}',
        unlimited: 'Ilimitado',
        images: 'Imagens',
        folder: 'Pasta',
        add_examination: 'Adicionar exame',
        right: 'Direita',
        left: 'Esquerda',
        label:
          '{{name}} / exame.: {{data}} / Olho: {{eye}} / digitalização: {{scan}}',
      },
      create_patient: {
        no_exam_left1: 'Não há mais exames. Você pode ',
        no_exam_left2: 'atualizar seu plano',
        no_exam_left3: ' para adicionar mais exames',
        create_new_patient_from_dicom:
          'Criar um novo paciente a partir de um DICOM',
        create_new_patient_from_dicom_description:
          'As informações sobre o paciente serão obtidas do arquivo DICOM',
        patient_id_label: 'ID do paciente',
        example_short: 'Ex',
        first_name_label: 'Primeiro nome do paciente',
        last_name_label: 'Segundo nome do paciente',
        birth_date_label: 'Data de nascimento do paciente',
        gender_label: 'Sexo',
        gender_placeholder: 'Escolha...',
        notes_label: 'Notas',
        add_examination: 'Adicionar exame',
        add_patient: 'Adicionar paciente',
        add_patient_and_examination: 'Adicionar paciente e exame',
        male: 'Masculino',
        female: 'Feminino',
        other: 'Outro',
      },
      current_plan: {
        annual: 'Anual',
        monthly: 'Mensal',
        days_left: 'Dias restantes:',
        examinations_left: 'Exames restantes:',
        unlimited: 'Ilimitado',
        invitations_left: 'Convites restantes:',
        no_scheduled_payment:
          'Você não tem o próximo pagamento programado porque não assinou nenhum plano',
        next_payment_related_to_your_upcoming_plan:
          'Seu próximo pagamento está relacionado ao seu próximo plano e é ',
        it_will_automatically_charge: 'Será cobrado automaticamente em ',
        your_next_payment_is: 'Seu próximo pagamento é ',
        to_be_charged: ', a ser cobrado em ',
        payment_will_be_charged_automatically:
          'O pagamento será cobrado automaticamente',
        you_successfully_unsubscribed:
          'Você cancelou a assinatura com sucesso de um ',
        plan: 'plano',
        your_subscription_is_active: 'Sua assinatura está ativa até ',
        subscribe: 'Assinar',
        unsubscribe: 'Cancelar assinatura',
        continue_subscriptions: 'Continuar assinaturas',
      },
      delete_exam: {
        cancel: 'Cancelar',
        delete: 'Excluir',
      },
      delete_patient: {
        cancel: 'Cancelar',
        delete: 'Excluir',
      },
      delete_team_member: {
        cancel: 'Cancelar',
        delete: 'Excluir',
      },
      download_dcm: {
        dont_have_dicom_file: 'Não tem o arquivo DICOM agora?',
        use_this: 'Use este!',
        download: 'Baixar',
        i_have_dicom: 'Tenho DICOM no meu PC',
        download_dicom: 'Baixar DICOM',
      },
      edit_scan_description: {
        oct_description_title: 'Descrição da análise Altris AI OCT',
        save: 'Salvar alterações',
      },
      export_json: {
        dicom_presentation_state: 'Estado de apresentação DICOM',
        file: 'Arquivo',
      },
      glaucoma_disclaimer: {
        title: 'Isenção de responsabilidade',
        i_acknowledged: 'Eu li e concordo',
      },
      image_cropper: {
        cancel: 'Cancelar',
        crop: 'Recortar',
      },
      invite_team_member: {
        email_label: 'E-mail',
        first_name_label: 'Primeiro nome do médico',
        last_name_label: 'Segundo nome do médico',
        example_short: 'Ex',
        send_the_invitation: 'Enviar convite',
      },
      no_objects_left: {
        examinations: {
          title: 'Você não pode adicionar mais exames',
          subtitle:
            'Não é possível adicionar novos exames até {{endData}}, pois o limite de exames para o seu plano de assinatura foi excedido neste mês.',
          subtext:
            'A partir de {{endData}}, você poderá adicionar novos exames novamente.',
        },
        plan: 'plano',
        annual: 'Anual',
        monthly: 'Mensal',
        days_left: 'Dias restantes:',
        renewal_starts_at: ' (Renovação começa em: {{endData}})',
        examinations_left: 'Exames restantes:',
        invitations_left: 'Convites restantes:',
        go_to_contact_page: 'Ir para a página de contato',
        please_contact_account_holder1: 'Entre em contato com o seu ',
        please_contact_account_holder2: 'Titular da conta',
        please_contact_account_holder3: ' para obter ajuda.',
        b2c: {
          buy_examinations_or_upgrade_plan1: 'Você também pode ',
          buy_examinations_or_upgrade_plan2: 'comprar exames extras',
          buy_examinations_or_upgrade_plan3: ' ou ',
          buy_examinations_or_upgrade_plan4: 'atualizar seu plano',
          upgrade_plan1: 'Você pode ',
          upgrade_plan2: 'atualizar seu plano',
          buy_plan: 'Comprar {{name}}',
          upgrade_to:
            'Atualizar para o plano {{name}} com {{count}} exames/mês',
          renew_subscription: 'Renovar assinatura',
          upgrade_to_custom_plan: 'Atualizar para um plano personalizado',
        },
      },
      onboarding_tour: {
        cancel: 'Cancelar (estou familiarizado com o Altris AI)',
        submit: 'Fazer um tour rápido',
      },
      renew_subscribe_reject: {
        please_contact_account_holder1: 'Entre em contato com o seu ',
        please_contact_account_holder2: 'Titular da conta',
        please_contact_account_holder3: ' para obter ajuda.',
        not_renew_subscription1:
          'Se você não vai renovar a assinatura, envie uma solicitação,',
        not_renew_subscription2: 'baixe seus dados ',
        not_renew_subscription3:
          'para não perdê-los. Caso tenha alguma dúvida, entre em contato conosco pelo e-mail: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      renew_subscribe_warning: {
        please_contact_account_holder1: 'Entre em contato com o seu ',
        please_contact_account_holder2: 'Titular da conta',
        please_contact_account_holder3: ' para obter ajuda.',
        not_renew_subscription1:
          'Se você não vai renovar a assinatura, envie uma solicitação,',
        not_renew_subscription2: 'baixe seus dados ',
        not_renew_subscription3:
          'para não perdê-los. Caso tenha alguma dúvida, entre em contato conosco pelo e-mail: ',
        not_renew_subscription4: 'support@altris.ai',
      },
      resend_team_member: {
        cancel: 'Cancelar',
        send_the_invitation: 'Enviar convite',
      },
      select_layers: {
        layers_grayscale: 'Camadas em tons de cinza',
        layers_grayscale_description:
          'Sua digitalização dentro do relatório ficará cinza.',
        confirm: 'Confirmar',
      },
      subscribe_plan: {
        subscription_update: 'Atualização de assinatura',
        are_you_sure_subscribe_free_plan:
          'Tem certeza de que deseja assinar o plano gratuito?',
        no: 'Não',
        yes: 'Sim',
        subscribe_with: 'Assinar',
        with_5_free_days: 'com 5 dias gratuitos',
        plan_charge:
          'Uma cobrança {{interval}} será de {{symbol}}{{price}}{{additional_item}}',
        monthly_plan_description:
          '*O desconto é válido apenas para os seis {{interval}} da assinatura',
        daily_plan_description:
          '*O desconto é válido apenas para o primeiro {{interval}} da assinatura',
      },
      survey_unsubscribe: {
        reason: 'Motivo',
        leave_your_feedback: 'Deixe seu feedback',
        submit: 'Enviar',
      },
      change_single_scan_tab: {
        dont_show_hint:
          'Não mostrar dica toda vez que eu abrir a guia Single Scan (esta opção pode ser alterada nas configurações)',
        later: 'Depois',
        open_pathologies_detection_tab: 'Abrir guia de detecção de patologias',
      },
      trial_period_end: {
        title: 'Período de avaliação expirado',
        subtitle:
          'Seu período de avaliação expirou em <0>{{date}}</0>. Para discutir a compra da versão completa do produto ou obter mais informações, entre em contato com nossa <1>equipe de vendas</1>.',
        button: 'Eu reconheci',
      },
      update_exam: {
        patient_id: 'ID do paciente',
        width: 'Largura',
        scan_width: 'Largura da digitalização',
        right: 'Direita',
        left: 'Esquerda',
        scan_width_message:
          'Certifique-se de ter selecionado a largura de digitalização correta, pois ela afeta as medições do exame',
        eye: 'Olho',
        type: 'Tipo',
        examination_date: 'Data do exame',
        notes_label: 'Notas',
        example_short: 'Ex',
        update_examination: 'Atualizar exame',
      },
      update_patient: {
        patient_id_label: 'ID do paciente',
        example_short: 'Ex',
        first_name_label: 'Primeiro nome do paciente',
        last_name_label: 'Segundo nome do paciente',
        birth_date_label: 'Data de nascimento do paciente',
        gender_label: 'Sexo',
        gender_placeholder: 'Escolha...',
        notes_label: 'Notas',
        add_examination: 'Adicionar exame',
        update_patient: 'Atualizar paciente',
        male: 'Masculino',
        female: 'Feminino',
        other: 'Outro',
      },
    },
    no_pathology: {
      title: 'Tudo bem!',
      subtitle: 'Nenhuma patologia detectada',
    },
    opacity_changer: {
      opacity: 'Opacidade',
    },
    open_exam_button: {
      text: 'Abrir este exame',
    },
    pathologies_progression: {
      title: 'Progressão de Patologias',
      add_another_examination:
        'Adicionar outro exame para rever a progressão das patologias',
      no_pathologies:
        'Não há opção para mostrar a progressão das patologias, pois nenhuma patologia foi detectada',
      selected_pathology: 'Patologia selecionada',
      volume: 'Volume',
      units_in_etdrs: 'Unidades em ETDRS',
      b_scan_area: 'Área B-Scan',
      en_face_area: 'Área En Face',
      total_change: 'Mudança Total',
      search_pathologies: 'Buscar Patologias',
      number_of_pathological: 'Número de áreas patológicas: {{number}}',
    },
    pay_by_stripe: {
      add_new_card: 'ou adicione um novo cartão de crédito/débito',
      pay_via: 'Pagar via Cartão de Crédito/Débito',
    },
    payment_info: {
      no_payments: 'Ainda não há pagamentos',
      you_can_subscribe: 'Você pode assinar o plano clicando no botão abaixo',
      subscribe_plan: 'Assinar Plano',
      description: 'Descrição',
      date: 'Data',
      time: 'Hora',
      charged: 'Cobrado',
      status: 'Status',
    },
    range_filter: {
      left: 'Esquerda',
      right: 'Direita',
      horizontal: 'Horizontal',
    },
    referral_score: {
      title: 'Índice de Urgência de Encaminhamento',
      good_message: 'Não é necessário encaminhamento',
      bad_message: 'Encaminhamento urgente necessário',
    },
    scan_description: {
      title: 'Descrição da análise Altris AI OCT',
      left: 'Esquerda',
      right: 'Direita',
      eye: 'Olho',
      average_rnfl_rpe:
        'Espessura média da RNFL-RPE em um anel foveal central com 1 mm de diâmetro',
      following_pathological_signs:
        'Os seguintes sinais patológicos foram visualizados',
      changes_could_be_classified_as:
        'Essas alterações podem ser classificadas como',
      edit: 'Editar',
      copy: 'Copiar',
      show_more: 'Mostrar mais',
      hide: 'Esconder',
    },
    scan_overlay: {
      length: 'Comprimento',
    },
    segmentation_table: {
      low_confidence: 'Baixa confiança',
      low_confidence_description:
        'A Altris AI detectou essa classificação com baixa confiança.',
      no_classification: 'Sem classificação para esta digitalização.',
      banner_tooltip:
        'Apenas as doenças cobertas pelo seu plano são mostradas. No entanto, várias outras patologias foram identificadas. Se quiser destacá-las, atualize seu plano.',
      more_pathologies_found:
        'Há mais patologias encontradas, que faltam em seu plano. Para analisá-las, atualize seu plano.',
      upgrade_plan: 'Atualizar meu plano',
    },
    severity_level: {
      no_severity: 'Sem severidade no exame',
      no_severity_plural: 'Sem severidade nos exames',
      yellow_severity_scan:
        'O exame inclui digitalização de severidade amarela',
      yellow_severity_scan_plural:
        'Digitalização de severidade amarela nos exames',
      red_severity_scan: 'O exame inclui digitalização de severidade vermelha',
      red_severity_scan_plural:
        'Digitalização de severidade vermelha nos exames',
    },
    severity_select: {
      sort: 'Classificar',
      sort_by: 'Classificar por',
      high_low: 'Severidade: Alto - Baixo',
      low_high: 'Severidade: Baixo - Alto',
      in_order: 'Em ordem',
    },
    single_scan: {
      add_to_report: 'Adicionar ao relatório',
      review_other_scans: 'Revisar outras digitalizações',
      scan_quality_tooltip1:
        'Observe que apenas digitalizações com qualidade 4 ou superior podem ser consideradas digitalizações de qualidade.',
      scan_quality_tooltip2:
        'A Altris AI ainda é capaz de classificá-las e segmentá-las, mas os resultados podem ser imprecisos.',
      central_scan: 'Digitalização central',
      severity: 'Severidade',
      severity_tooltip:
        'As digitalizações são marcadas de acordo com a gravidade dos sinais patológicos. Verde - retina normal, Amarelo - gravidade moderada e Vermelho - grave.',
      yellow: 'amarelo',
      red: 'vermelho',
      green: 'verde',
      draw_a_line: 'Desenhar uma linha',
      draw_a_line_info: 'Pressione o botão esquerdo do mouse e arraste',
      segmentations: 'Segmentações',
      highlight: 'Realçar',
    },
    smart_report: {
      average_thickness: 'Espessura média de 1/3/6 mm, μm',
      gcc_thickness: 'Espessura GCC',
      both_eyes_view: 'Visualização de ambos os olhos',
      thickness_comparison: 'Comparação de espessura',
      retina_thickness_difference: 'Diferença de espessura da retina',
      etdrs_calculations: 'Cálculos ETDRS',
      from: 'De',
      to: 'até',
      etdrs_titles: {
        average_thickness: 'Espessura média',
        single_layer_thickness: 'Espessura de camada única',
        multiple_layer_thickness: 'Espessura de camada múltipla',
        gcc_thickness: 'Espessura GCC',
      },
      glaucoma: 'Glaucoma',
      auto: 'Automático',
      auto_measurements: 'Medições automáticas',
      manual: 'Manual',
      manual_measurements: 'Medições manuais',
      segmentation_area_title:
        'Área de segmentação | Digitalização: {{number}}/{{total}}',
      segmentation_volume_title:
        'Volume de segmentação | Digitalização: {{number}}/{{total}}',
      retina_layers: 'Camadas da retina',
      segmentation: 'Segmentação',
      classification: 'Classificação',
      single_scan: 'Digitalização única',
      right: 'Direita',
      left: 'Esquerda',
      report_results: 'Resultados do relatório',
      clinic: 'Nome da clínica',
      clinic_email: 'E-mail da clínica',
      doctor: 'Médico',
      patient_name: 'Nome do paciente',
      birth_day: 'Data de nascimento',
      gender: 'Sexo',
      eye: 'Olho',
      exam_date: 'Data do exame',
      total_scans: 'Total de digitalizações',
      pathology_progression: 'Progressão da patologia',
      selected_pathology: 'Patologia selecionada:',
      en_face_area: 'Área en face',
      b_scan_area: 'Área de varredura B',
      volume: 'Volume',
      total_change: 'Mudança total',
    },
    spinner_svg: {
      retry: 'Tentar Novamente',
    },
    subscription_conditions: {
      back: 'Voltar',
      detection: 'Detecção de sinais patológicos com IA (Segmentação)',
      detection_and_visualization:
        'Detecção e visualização de sinais patológicos com IA (Segmentação)',
      view_all_pathological_signs: 'Visualizar todos os sinais patológicos',
    },
    subscription_plan_card: {
      need_better_solution: 'Precisa de uma solução melhor para sua clínica?',
      personal_assistant: 'Assistente pessoal',
      unlimited_examinations: 'Exames ilimitados',
      unlimited_patients: 'Pacientes ilimitados',
      separate_accounts_for_doctors: 'Contas separadas para médicos',
      onboarding_trainings_for_the_whole_team:
        'Treinamentos de integração para toda a equipe',
      platform_customization:
        'Personalização da plataforma de acordo com suas necessidades e muito mais.',
      contact: 'Contato',
      month: 'Mês',
      you_saving: 'Você está economizando',
      starting_from: 'A partir de',
      details: 'Detalhes',
      current_plan: 'Plano atual',
      active_till: 'Ativo até',
      subscribe: 'Assinar',
      days_free_trial: '{{number}} dias de teste gratuito',
      best_choice: 'Melhor escolha',
      monthly_description:
        '*O desconto é válido apenas para os seis {{pricingType}} da assinatura',
      daily_description:
        '*O desconto é válido apenas para o primeiro {{pricingType}} da assinatura',
      unlimited: 'Ilimitado',
      examinations_for_ai_screening:
        'Exames para TRIAGEM DE IA, SEGMENTAÇÃO DE CAMADAS DE IA',
      pathological_signs_detection:
        'Detecção e visualização de sinais patológicos com IA',
      try_for_free: 'Experimente grátis',
      month_small: 'mês',
      year_small: 'ano',
    },
    subscription_plans: {
      subscription: 'Assinatura',
      price: 'Preço',
      examinations_available: 'Exames disponíveis',
      detect: 'Detectar',
      retina_conditions: 'Condições da Retina',
      tooltip_text:
        'Esta funcionalidade está em desenvolvimento e aparecerá na plataforma quando estiver pronta',
      services: 'Serviços',
      service: 'Serviço',
    },
    team_member_list: {
      doctors: 'Médicos',
      email: 'E-mail',
      status: 'Status',
      access_level: 'Nível de acesso',
      examination_created: 'Exame criado',
      actions: 'Ações',
      no_doctors: 'Nenhum médico foi convidado ainda',
      you_can_add_team_member:
        'Você pode adicionar um novo membro da equipe pressionando',
      invite_doctor: 'Convidar médico',
      me: 'EU',
      no_actions: 'Sem ações',
      resend_invitation: 'Reenviar convite',
      delete: 'Excluir',
      active: 'ATIVO',
      pending: 'PENDENTE',
      invite_expired: 'CONVITE EXPIRADO',
    },
    titled_switcher: {
      on: 'LIGADO',
      off: 'DESLIGADO',
    },
    upload_image: {
      upload_photo: 'Carregar foto',
      info: 'Formato: {{formats}}. O tamanho máximo do arquivo é {{size}}.',
    },
    welcome: {
      title:
        'Torne a interpretação de varreduras OCT fácil com a plataforma Altris AI',
      subtitle1:
        'A plataforma Altris AI só funciona em Windows ou Mac (NÃO é um aplicativo móvel).',
      subtitle2: 'Embora o registro possa ser feito através do seu celular',
      list_item_title1:
        'Diferenciação entre b-scans patológicos e não patológicos em 1 minuto',
      list_item_title2:
        'Detecção de mais de 70 patologias da retina, incluindo patologias raras',
      list_item_title3: 'Análise da espessura das camadas da retina',
      how_it_works: 'Como funciona?',
      description1:
        'A Altris AI adere totalmente ao GDPR, garantindo a conformidade. Todos os dados do usuário são criptografados e inacessíveis a terceiros. Possuímos um certificado CE e autorização FDA 510, afirmando nosso compromisso com os padrões regulatórios.',
      description2:
        'A plataforma foi criada por uma equipe de especialistas em retina sob a supervisão do Diretor Médico, Ph.D. em Oftalmologia.',
      description3:
        'A Altris AI é destaque no Ophthalmology Times, Eyewire, Eyes on Eyecare, The Ophthalmologist e é apoiada pela comunidade de cuidados oftalmológicos.',
      features_item_title1: 'Formato.',
      features_item_title2: 'Comunicação.',
      features_item_title3: 'Integração.',
      features_item_text1:
        'O sistema funciona com todos os formatos de dados, como DICOM, jpg e png, tornando o sistema de gerenciamento de imagens oftálmicas mais seguro e eficiente.',
      features_item_text2:
        'Os especialistas em cuidados oftalmológicos podem compartilhar imagens dentro do sistema, discuti-las deixando comentários e trabalhando nas mesmas imagens em conjunto.',
      features_item_text3:
        'O sistema também pode ser integrado ao sistema EHR ou pode funcionar de forma autônoma como um aplicativo web.',
      disclaimer:
        'Aviso: Altris AI está disponível apenas como software de pesquisa nos EUA. A liberação do FDA está em andamento.',
      i_acknowledged: 'Eu reconheço',
      learn_more: 'Saiba mais',
      back: 'Voltar',
      next: 'Próximo',
      slide_one: {
        title: 'Bem-vindo ao Altris AI',
        subtitle:
          'Analise o exame OCT com Altris AI. Economize seu tempo e nunca perca patologias raras, menores ou iniciais',
        desktop_available:
          'A plataforma Altris AI só funciona em Windows ou Mac. Não é um aplicativo móvel.',
        registration_with_phone:
          'Embora o registro possa ser feito através do seu celular',
        description:
          'Totalmente compatível com GDPR, os dados são criptografados e não podem ser acessados por nenhum terceiro. Temos um certificado CE e o FDA está em processo de liberação.',
      },
      slide_two: {
        title: 'Gravidade - Analisar varreduras',
        subtitle:
          'Analise a gravidade de até 512 varreduras patológicas em um minuto',
        low_severity: 'Baixa severidade',
        medium_severity: 'Média severidade',
        high_severity: 'Alta severidade',
        green: 'verde',
        yellow: 'amarelo',
        red: 'vermelho',
        description:
          'Não precisa perder tempo revisando todas as varreduras para encontrar a área de interesse',
      },
      slide_three: {
        title: 'Detecção e visualização de patologias',
        subtitle:
          'A Altris AI detecta mais de 70 condições da retina para cada varredura no exame',
        segmentation: 'Segmentação',
        classification: 'Classificação',
      },
      slide_four: {
        title: 'E muito mais para aproveitar ao máximo a plataforma',
        item_header1: 'Segmentação das camadas da retina',
        item_header2: 'Mapa de espessura de EDTRS e Retina',
        item_header3: 'Pontuação de urgência de encaminhamento',
        item_header4: 'Medidas automáticas de patologias',
        item_header5: 'Área de segmentação',
        item_header6: 'Volume de segmentação',
        item_header7: 'Relatórios inteligentes',
        item_text2: 'Espessura média de 1/3/6 mm e mapa de espessura da retina',
        item_text3:
          "A pontuação de urgência de encaminhamento da Altris AI depende dos sinais patológicos descobertos. Varia de 'Não é necessário encaminhamento' a 'Encaminhamento urgente necessário'",
        item_text4:
          "A pontuação de urgência de encaminhamento da Altris AI depende dos sinais patológicos descobertos. Varia de 'Não é necessário encaminhamento' a 'Encaminhamento urgente necessário'",
        item_text5:
          'A Altris AI calcula automaticamente a área de todas as segmentações',
        item_text6:
          'A Altris AI calcula automaticamente o volume de todas as segmentações',
      },
    },
  },
  constants: {
    severity_filter_options: {
      all: 'todos',
      green: 'verde',
      yellow: 'amarelo',
      red: 'vermelho',
    },
  },
  modals: {
    glaucoma_disclaimer:
      'Por favor, note que a análise de risco de glaucoma só funciona em exames de OCT onde a patologia não altera a espessura do complexo de células ganglionares',
    sign_out: {
      title: 'Você está tentando sair',
      subtitle: 'Tem certeza?',
      yes: 'Sim',
      cancel: 'Cancelar',
    },
    add_patient: {
      title: 'Adicionar novo paciente',
      subtitle:
        'Preencha o formulário abaixo para adicionar um novo cartão de paciente à lista',
    },
    add_examination: 'Adicionar novo exame',
    add_eye_examination: {
      title: 'Adicionar exame ocular',
      subtitle:
        'Preencha o formulário abaixo para adicionar um novo exame à lista',
    },
    invite_team_member: {
      title: 'Convidar um médico',
      subtitle:
        'Preencha o formulário abaixo para adicionar um novo médico à lista',
    },
    delete_team_member: {
      title: 'Excluir',
      subtitle: 'Você realmente deseja excluir esta conta?',
    },
    reinvite_team_member: {
      title: 'Reenviar convite',
      subtitle: 'Você realmente deseja reenviar o convite para esta conta?',
    },
    delete_patient: {
      title: 'Excluir',
      subtitle: 'Você realmente deseja excluir este paciente?',
    },
    update_patient: {
      title: 'Editar paciente',
      subtitle:
        'Preencha o formulário abaixo para editar o cartão do paciente na lista',
    },
    unsubscribe: {
      title: 'Você está tentando cancelar a assinatura',
      subtitle: 'Tem certeza?',
      yes: 'Sim',
      cancel: 'Cancelar',
    },
    subscribe_fake: {
      title: 'Atualização de assinatura',
      subtitle: 'Você assinará o plano gratuito',
      yes: 'Sim',
      cancel: 'Cancelar',
    },
    renew_subscribe_warning: {
      title1: 'Renovar assinatura',
      subtitle1:
        'Infelizmente, não recebemos o pagamento. A assinatura que você está usando expirou. Atualize para um novo plano de assinatura para Altris AI ou seu acesso ao Altris AI será removido',
      button_text1: 'Renovar assinatura',
      title2: 'Renovar assinatura',
      subtitle2:
        'Infelizmente, não recebemos o pagamento e seu plano expirou. Renove sua assinatura ou seu acesso ao Altris AI será removido',
      button_text2: 'Renovar',
    },
    renew_subscribe_warning_b2b: {
      title: 'Renovar assinatura',
      subtitle:
        'Infelizmente, não recebemos o pagamento e seu plano expirou. Renove sua assinatura ou seu acesso ao Altris AI será removido',
      button_text: 'Ir para a página de contato',
    },
    renew_subscribe_reject: {
      title1: 'Renovar assinatura',
      subtitle1:
        'Infelizmente, não recebemos o pagamento. A assinatura que você está usando expirou. Atualize para um novo plano de assinatura. Armazenaremos seus dados até {{date}}. Depois disso, teremos que excluí-los.',
      button_text1: 'Renovar assinatura',
      title2: 'Renovar assinatura',
      subtitle2:
        'Infelizmente, não recebemos o pagamento e seu plano expirou. Renove sua assinatura. Armazenaremos seus dados até {{date}}. Depois disso, teremos que excluí-los.',
      button_text2: 'Renovar',
    },
    renew_subscribe_reject_b2b: {
      title: 'Renovar assinatura',
      subtitle:
        'Infelizmente, não recebemos o pagamento e seu plano expirou. Renove sua assinatura. Armazenaremos seus dados até {{date}}. Depois disso, teremos que excluí-los.',
      button_text: 'Acesse a página Fale Conosco',
    },
    analyze_scans: {
      title: 'Analisar outras varreduras',
      subtitle: 'Marque as varreduras que deseja analisar.',
    },
    select_retina_layers:
      'Selecione as camadas da retina para adicionar ao relatório',
    delete_examination: {
      title: 'Excluir',
      subtitle: 'Você realmente deseja excluir este exame?',
    },
    update_examination: {
      title: 'Editar',
      subtitle:
        'Preencha o formulário abaixo para editar o cartão de exame na lista',
    },
    onboarding_tour: {
      title: 'Bem-vindo ao Altris AI',
      subtitle: 'O que você pode esperar da Altris AI?',
    },
    json_exam_export: {
      title: 'Exportar seus dados do exame.',
      subtitle: 'Escolha quais dados deseja exportar.',
    },
    unsubscribe_survey: {
      title: 'O que deu errado?',
      subtitle:
        'Percebemos que você cancelou a assinatura do Altris Pro. Por favor, nos informe o porquê?',
    },
    booking_meeting: {
      title: 'Nós podemos resolver isso!',
      subtitle:
        'Agende uma reunião gratuita com nosso especialista, que possui 15 anos de experiência em OCT e pode responder a todas as suas perguntas sobre a Altris AI.',
    },
    download_dicom: {
      title: 'Crie seu primeiro paciente e exame!',
      subtitle:
        'Para criar um exame, use um arquivo DICOM ou uma imagem exportada do seu OCT.',
    },
    download_dicom_success: {
      title: 'Download do DICOM iniciado!',
      subtitle:
        'Aguarde até o download terminar... Depois, clique em “Avançar” para criar um novo paciente. Em seguida, crie um novo exame e envie o arquivo DICOM para ser analisado pela Altris AI.',
    },
    change_single_scan_tab:
      'Não se esqueça de revisar a Detecção de Patologias (IA Avançada!)',
  },
  validation: {
    required: 'Obrigatório',
    data_invalid: 'Dados fornecidos inválidos',
    email_invalid: 'E-mail fornecido inválido',
    letters_numbers_spaces_hyphens:
      'Somente letras (en, ua, ru), números, espaços, pontos e hifens (-) são permitidos neste campo',
    min_chars: 'Deve conter no mínimo {{number}} caracteres',
    max_chars: 'Deve conter no máximo {{number}} caracteres',
    password_latin_lowercase: 'A senha deve conter letras minúsculas latinas',
    password_latin_uppercase: 'A senha deve conter letras maiúsculas latinas',
    password_number: 'A senha deve conter um número',
    password_match: 'As senhas devem coincidir',
  },
  notifications: {
    you_can_add_only_2_dicom: 'Você só pode adicionar 2 arquivos DICOM',
    your_data_was_added_successfully:
      'Seus dados foram adicionados com sucesso',
    you_cant_add_file_of_this_format:
      'Você não pode adicionar um arquivo desse formato',
    patient_was_added_successfully: 'Paciente adicionado com sucesso',
    characters_limit_exceeded: 'Limite de caracteres excedido ({{number}})',
    download_will_started_automatically:
      'O download será iniciado automaticamente quando todos os dados estiverem prontos.',
    please_enter_your_card: 'Por favor, insira o seu cartão',
    image_size_changed_successfully: 'Tamanho da imagem alterado com sucesso',
    description_copied: 'Descrição copiada',
    description_not_copied: 'Descrição não copiada',
    you_already_add_this_scan_form_segmentation_report:
      'Você já adicionou essa varredura do relatório de segmentação',
    scan_was_successfully_added_to_the_report:
      'A varredura foi adicionada com sucesso ao relatório',
    image_was_not_uploaded:
      'A imagem não foi carregada porque é muito grande. Envie uma imagem com menos de 3 MB',
    resent_confirmation_email: 'E-mail de confirmação reenviado',
    something_went_wrong: 'Algo deu errado',
    examination_etdrs_calculation_finished:
      'Exame: {{id}}. Cálculo ETDRS finalizado',
    examination_etdrs_calculation_failed:
      'Exame: {{id}}. Falha no cálculo ETDRS',
    segmentation_layers_calculation_finished:
      'Exame: {{id}}. Cálculo das camadas de segmentação finalizado',
    segmentation_layers_calculation_failed:
      'Exame: {{id}}. Falha no cálculo das camadas de segmentação',
    measurements_calculation_finished:
      'Exame: {{id}}. Cálculo das medidas finalizado',
    measurements_calculation_failed:
      'Exame: {{id}}. Falha no cálculo das medidas',
    measurements_manual_updated: 'Medidas atualizadas manualmente',
    link_is_copied: 'Link copiado',
    link_not_copied: 'Link não copiado',
    your_request_sent_successfully: 'Sua solicitação foi enviada com sucesso',
    you_dont_have_available_pathology_classes:
      'Você não possui classes de patologia disponíveis',
    examination_created_successfully: 'Exame criado com sucesso',
    examination_deleted_successfully: 'Exame excluído com sucesso',
    examination_failed: 'Falha no exame',
    examination_available: 'Seu exame está disponível',
    examination_updated_successfully: 'Exame atualizado com sucesso',
    scan_added_to_favorites: 'Varredura adicionada aos favoritos',
    scan_removed_from_favorites: 'Varredura removida dos favoritos',
    measurements_reset_successfully:
      'As medidas para esta varredura foram redefinidas',
    measurements_updated: 'Medidas atualizadas',
    you_tried_upload_same_eye: 'Você tentou enviar o mesmo olho',
    you_tried_upload_opposite_eye: 'Você tentou enviar o olho oposto',
    limit_examination_is_reached: 'Limite de exames atingido',
    examinations_have_not_enough_data: 'Seu exame não possui dados suficientes',
    patient_deleted_successfully: 'Paciente excluído com sucesso',
    patient_updated_successfully:
      'Informações do paciente atualizadas com sucesso',
    you_successfully_buy_additional_examination:
      'Você comprou um exame adicional com sucesso',
    settings_saved: 'Suas configurações foram salvas',
    subscription_plan_updated: 'Seu plano de assinatura foi atualizado',
    subscription_canceled: 'Sua assinatura foi cancelada',
    doctor_invited: 'Médico convidado',
    password_changed: 'Sua senha foi alterada',
    backup_request_created:
      'Solicitação criada, você receberá um e-mail quando seus dados estiverem prontos',
    data_deleted: 'Seus dados foram excluídos',
    attempts_over: 'Suas tentativas acabaram, tente novamente mais tarde',
    company_profile_updated: 'Seu perfil da empresa foi atualizado',
    profile_updated: 'Seu perfil foi atualizado com sucesso',
    session_expired: 'Sua sessão expirou, por favor faça login',
    site_under_maintenance:
      'Esteja ciente de que o site está em manutenção regular no momento, e todos os serviços podem funcionar incorretamente temporariamente',
    progression_settings_updated:
      'As configurações de progressão foram atualizadas com sucesso.',
  },
  user_popup_menu_links: {
    subscription: 'Assinatura',
    contact_support: 'Contatar suporte',
  },
  profile_links: {
    subscriptions: 'Assinaturas',
    team_members: 'Membros da equipe',
    contact_us: 'Entre em contato',
    documentation: 'Documentação',
    join_altris_education: 'Participe da Educação Altris',
    settings: 'Configurações',
    onboarding: 'Integração',
    storybook: 'Livro de histórias',
  },
  onboarding_links: {
    complete_onboarding_again: 'Completar a integração novamente',
    download_test_dicom: 'Baixar DICOM de teste',
    create_patient: 'Criar paciente',
    create_examintaion: 'Criar exame',
    header: 'Cabeçalho',
    single_scan: 'Varredura única',
    pathologies_detection: 'Detecção de patologias',
    measurements: 'Medições',
    all_scans: 'Todas as varreduras',
    both_eyes: 'Ambos os olhos',
    comparison: 'Comparação',
    progression: 'Progressão',
    report: 'Relatório',
  },
  onboarding: {
    create_examination: {
      add_new_examination: 'Adicionar novo exame',
      next: 'Próximo',
      examination_list: 'Lista de exames',
      step_1_text_1: 'Clique',
      step_1_text_2: 'para adicionar um novo exame de OCT ao seu paciente',
      step_1_text_3: 'Clique para avançar',
      step_2_text_1: 'O primeiro passo seria',
      step_2_text_2: 'Selecionar o paciente',
      step_3_text: 'Ou selecione para Criar exame E Paciente usando DICOM',
      step_4_text_1: 'O que é DICOM?',
      step_4_text_2: 'Estou familiarizado com o DICOM',
      step_4_text_3: 'Você pode criar exame fazendo upload de',
      step_4_text_4: 'DICOM (Preferido), pasta DICOM',
      step_4_text_5: 'ou imagens',
      step_4_text_6:
        'Importante! Certifique-se de saber o que é DICOM e como exportá-lo do seu OCT',
      step_5_text_1:
        'Arraste o DICOM para a janela para selecioná-lo para ser carregado',
      step_5_text_2: 'Carregue DICOM para avançar',
      step_6_text_1:
        'Depois que o arquivo DICOM/Imagens for adicionado, você pode clicar em',
      step_6_text_2: 'Clique para avançar',
      step_7_text:
        'E agora o exame foi adicionado e estará acessível após o sistema analisá-lo. Você pode revisar a lista de todos os exames do paciente depois de clicar nele.',
      step_8_text_1: 'Vamos revisar como é o exame',
      step_8_text_2: 'Clique para avançar',
      step_dicom_text_1: 'Eu reconheci',
      step_dicom_text_2: 'O que é arquivo DICOM/DCOM/.dcm?',
      step_dicom_text_3: 'DICOM/DCOM/.dcm',
      step_dicom_text_4: 'é um formato de arquivo, que você pode selecionar',
      step_dicom_text_5: 'para exportar o exame',
      step_dicom_text_6: 'do seu OCT.',
      step_dicom_text_7: 'Inclui:',
      step_dicom_text_8: 'Todos os escaneamentos do Exame de OCT',
      step_dicom_text_9:
        'Você pode selecionar quantos escaneamentos deseja exportar para DICOM no seu OCT',
      step_dicom_text_10: 'Dados de exame necessários',
      step_dicom_text_11:
        'Sugerimos usar DICOM em vez de imagens. Como as imagens não incluem todos os dados necessários do exame, vários componentes principais do sistema não serão capazes de representar dados convenientes',
      step_dicom_text_12: 'O que não estará disponível se eu usar imagens?',
      step_dicom_text_13: 'Dados necessários sobre o paciente',
      step_dicom_text_14:
        'Se você usar DICOM em vez de Imagem, podemos puxar todos os dados necessários sobre o paciente e criá-los automaticamente',
      step_dicom_text_15: 'Como posso criar um paciente usando DICOM?',
      step_dicom_text_16: 'Pasta DICOM',
      step_dicom_text_17:
        'Se o seu OCT só puder exportar dados em uma pasta (por ex., OCT da Heidelberg Engineering) você pode arquivar esta pasta usando o seu arquivador padrão e fazer upload de um arquivo .zip ao criar um exame. NÃO altere a estrutura da pasta ou arquive qualquer pasta separada dentro da principal. A pasta deve ser arquivada inteiramente.',
      step_dicom_text_18:
        'Como Exportar Pasta DICOM do OCT da Heidelberg Engineering?',
      step_dicom_text_19: 'Como posso exportar arquivo DICOM do meu OCT?',
      step_dicom_text_20: 'Como Exportar DICOM do OCT da Nidek?',
      step_dicom_text_21: 'Como Exportar DICOM do OCT da Topcon?',
      step_dicom_text_22: 'Como Exportar DICOM do OCT da Optopol?',
      step_scans_text_1: 'Apenas escaneamentos de qualidade!',
      step_scans_text_2:
        'Por favor, certifique-se de que os escaneamentos que você está fazendo upload têm qualidade suficiente. Caso contrário, o resultado da análise não será satisfatório.',
    },
    create_patient: {
      add_new_patient: 'Adicionar novo paciente',
      next: 'Próximo',
      step_1_text_1: 'Vamos começar adicionando um novo paciente!',
      step_1_text_2: 'para criar um novo paciente',
      step_1_text_3: 'Clique aqui para avançar',
      step_2_text_1:
        'Você pode criar imediatamente Paciente e Exame fazendo upload de arquivo DICOM',
      step_2_text_2: 'Basta selecionar a caixa de seleção!',
      step_3_text_1:
        'Se você selecionou para não carregar o paciente a partir de DICOM, você precisa preencher todas as informações sobre o paciente.',
      step_3_text_2:
        'Depois que todos os dados do paciente forem preenchidos, clique',
      step_4_text_1:
        'Após o DICOM ser carregado ou todas as informações do paciente serem preenchidas, você pode clicar',
      step_4_text_2: 'para salvar.',
      step_4_text_3: 'Clique para avançar',
      step_5_text_1: 'Lista de pacientes',
      step_5_text_2:
        'E agora seu paciente foi adicionado, então você pode criar o Exame',
      step_5_text_3:
        'Nota: Se você carregou o paciente usando DICOM, esta etapa será automaticamente pulada',
    },
    create_report: {
      create_report: 'Criar relatório',
      next: 'Próximo',
      step_1_text_1: 'Após o exame descoberto, o médico pode criar um',
      step_1_text_2: 'Relatório',
      step_1_text_3: 'Clique para avançar',
      step_2_text_1: 'Passo 1',
      step_2_text_2:
        'Selecione o exame que deseja mostrar como o principal (última revisão por padrão)',
      step_2_text_3: 'Passo 2',
      step_2_text_4: 'Selecione quais componentes deseja incluir no relatório',
      step_2_text_5: 'Passo 3',
      step_2_text_6:
        'Selecione um ou vários exames para mostrar a detecção de patologias',
      step_3_text_1: 'Imprimir ou Salvar PDF',
      step_3_text_2: 'Passo 4',
      step_3_text_3: 'Clique',
      step_3_text_4: 'Revisão',
      step_3_text_5:
        'para ver a prévia do relatório gerado e Imprimir/Salvar em PDF',
      step_3_text_6: 'Clique para avançar',
      step_4_text_1: 'Parabéns!',
      step_4_text_2: 'Você concluiu o onboarding!',
      step_4_text_3: 'Sinta-se à vontade para criar seu primeiro paciente!',
      step_4_text_4: 'Você sempre pode revisar esta instrução novamente em',
      step_4_text_5: 'Configurações',
    },
    general: {
      skip_onboarding: 'Pular Onboarding',
      are_you_sure_want_to_skip:
        'Tem certeza de que deseja pular o onboarding?',
      continue_onboarding: 'Continuar Onboarding',
    },
    show_examination: {
      tab_menu: 'Menu de Abas',
      next: 'Próximo',
      all_scans_text_1: 'Vamos continuar com',
      all_scans_text_2: 'Todos os Exames',
      all_scans_text_3: 'Clique para avançar',
      all_scans_text_4: 'Todos os Exames',
      all_scans_text_5:
        'Você pode revisar todos os exames e classificá-los por Nível de Severidade.',
      all_scans_text_6:
        'Também pode selecionar para visualizar apenas os exames que foram categorizados como',
      all_scans_text_7: 'verde',
      all_scans_text_8: 'amarelo',
      all_scans_text_9: 'ou',
      all_scans_text_10: 'vermelho',
      all_scans_text_11: 'Nível de Severidade.',
      both_eyes_text_1: 'Vamos continuar com',
      both_eyes_text_2: 'Ambos os Olhos',
      both_eyes_text_3: 'Clique para avançar',
      both_eyes_text_4: 'Ambos os olhos',
      both_eyes_text_5:
        'Você pode adicionar o segundo olho para revisar a imagem completa',
      comparison_text_1: 'Vamos continuar com',
      comparison_text_2: 'Comparação',
      comparison_text_3: 'Clique para avançar',
      comparison_text_4: 'Comparação',
      comparison_text_5:
        'Adicione o mesmo olho, diagnosticado anteriormente, para comparar com o exame atual',
      measurements_text_1: 'Vamos continuar com',
      measurements_text_2: 'Medições',
      measurements_text_3: 'Clique para avançar',
      measurements_text_4: 'Medições',
      measurements_text_5: 'A seção de Medições permite aos médicos:',
      measurements_text_6: 'Revisar',
      measurements_text_7: 'Altura',
      measurements_text_8: 'e',
      measurements_text_9: 'Comprimento',
      measurements_text_10: 'das segmentações, medidos automaticamente',
      measurements_text_11: 'Desenhar',
      measurements_text_12: 'Linhas para calcular área de interesse',
      measurements_text_13: 'Revisar Áreas de Segmentação',
      measurements_text_14: 'Revisar Volumes de Segmentação',
      measurements_text_15:
        'Você pode revisar a Altura e o Peso da segmentação detectada',
      measurements_text_16: 'Auto-medições',
      measurements_text_17:
        'Iniciar medições e revisar Altura e Comprimento da segmentação',
      measurements_text_18: 'Destacar ou Remover destaque nelas nos exames',
      measurements_text_19: 'Editar linha para recalcular',
      measurements_text_20: 'Excluir',
      measurements_text_21: 'Vamos continuar com',
      measurements_text_22: 'Medições Automáticas',
      measurements_text_23: 'Vamos continuar com',
      measurements_text_24: 'Medição Manual',
      measurements_text_25: 'Clique para avançar',
      measurements_text_26: 'Vamos continuar com',
      measurements_text_27: 'Área de Segmentação',
      measurements_text_28: 'Clique para avançar',
      measurements_text_29: 'Medições Manuais',
      measurements_text_30:
        'Você também pode desenhar uma linha para calcular a distância entre dois pontos no exame.',
      measurements_text_31: 'Área de Segmentação',
      measurements_text_32: 'Revisar Áreas Segmentadas:',
      measurements_text_33: 'B-Scan',
      measurements_text_34: 'En-face',
      measurements_text_35: 'Vamos continuar com',
      measurements_text_36: 'Volume de Segmentação',
      measurements_text_37: 'Clique para avançar',
      measurements_text_38: 'Medições',
      measurements_text_39: 'Revisar volume de segmentação:',
      pathologies_detection_text_1: 'Vamos continuar com',
      pathologies_detection_text_2: 'Detecção de Patologias',
      pathologies_detection_text_3: 'Clique para avançar',
      pathologies_detection_text_4: 'Segmentação e classificação',
      pathologies_detection_text_5:
        'Revisar o último exame selecionado e Destacar ou Remover destaque',
      pathologies_detection_text_6: 'segmentação',
      pathologies_detection_text_7: 'Segmentação e classificação',
      pathologies_detection_text_8:
        'Dependendo do Nível de Severidade, a Altris AI sugere uma',
      pathologies_detection_text_9: 'Pontuação de Urgência de Encaminhamento',
      pathologies_detection_text_10: 'Esta opção pode ser desativada',
      pathologies_detection_text_11: 'Segmentação',
      pathologies_detection_text_12:
        'A Altris AI analisa o exame e sugere todas as',
      pathologies_detection_text_13: 'Segmentações',
      pathologies_detection_text_14: 'detectadas no exame selecionado.',
      pathologies_detection_text_15:
        'Você pode revisá-las no exame e Destacar/Remover destaque',
      pathologies_detection_text_16: 'Revisar Outros Exames',
      pathologies_detection_text_17: 'Você pode',
      pathologies_detection_text_18: 'Selecionar outros exames',
      pathologies_detection_text_19: 'para serem exibidos e',
      pathologies_detection_text_20: 'Adicionar ao relatório',
      pathologies_detection_text_21: 'atual',
      pathologies_detection_text_22: 'Classificação',
      pathologies_detection_text_23:
        'A Altris AI analisa o exame e sugere todas as',
      pathologies_detection_text_24: 'Classificações',
      pathologies_detection_text_25: 'detectadas no exame selecionado',
      progression_text_1: 'Vamos continuar com',
      progression_text_2: 'Progressão',
      progression_text_3: 'Clique para avançar',
      progression_text_4: 'Progressão',
      progression_text_5:
        'Adicione até 8 exames para revisar como as Segmentações progridem',
      single_scan_text_1: 'Informações do Paciente/Exame',
      single_scan_text_2:
        'No topo da tela, você pode revisar informações gerais sobre o Paciente e o Exame',
      single_scan_text_3:
        'Abaixo, você pode ver o menu de abas com todos os componentes do sistema',
      single_scan_text_4: 'Exame Único',
      single_scan_text_5: 'No lado direito, você pode ver:',
      single_scan_text_6: 'Imagem do exame',
      single_scan_text_7: 'Qualidade do exame',
      single_scan_text_8: 'Número do exame',
      single_scan_text_9: 'Nível de Severidade do exame',
      single_scan_text_10: 'Botão de escala',
      single_scan_text_11: 'Botão para selecionar o exame Central',
      single_scan_text_12: "Botão para marcar o exame como 'Selecionado'",
      single_scan_text_13: 'Exame Único',
      single_scan_text_14: 'No lado esquerdo, você pode ver:',
      single_scan_text_15: 'Tipo de exame',
      single_scan_text_16: 'Altris suporta apenas B-scans horizontais',
      single_scan_text_17: 'Olho',
      single_scan_text_18: 'Níveis de severidade e navegação',
      single_scan_text_19:
        'Clique na área selecionada para revisar o exame ou arraste a linha',
      single_scan_text_20: 'Filtrar por Severidade',
      single_scan_text_21: 'Número de exames de cada nível de Severidade',
      single_scan_text_22: 'Botão para selecionar o exame Central',
      single_scan_text_23: 'Exame Único',
      single_scan_text_24: 'Você pode ver as',
      single_scan_text_25: 'Camadas da Retina',
      single_scan_text_26: 'segmentação',
      single_scan_text_27: 'para o exame selecionado.',
      single_scan_text_28: 'Você pode',
      single_scan_text_29: 'Destacar',
      single_scan_text_30: 'e Remover destaque de qualquer',
      single_scan_text_31: 'ETDRS e Mapa de espessura da retina',
      single_scan_text_32:
        'Selecione a visualização do Mapa de espessura da retina ou Visualização padrão',
      single_scan_text_33: 'Espessura média de 1/3/6 mm',
      single_scan_text_34: 'Exame Único',
      single_scan_text_35: 'Espessura da GCC',
      single_scan_text_36: 'Espessura de Camada DE / PARA',
      single_scan_text_37: 'Espessura de Camada Única',
      single_scan_text_38: 'Vamos começar com',
      single_scan_text_39: 'Exame Único',
    },
  },
  common: {
    eyeType: {
      OS: 'Left (OS)',
      OD: 'Right (OD)',
    },
  },
};

export default translation;
